<template>
  <v-container id="changelog" fluid tag="section">
    <change-request />
    <hr />
    <changelog-de v-if="$i18n.locale == 'de'" />
    <changelog-en v-if="$i18n.locale == 'en'" />
  </v-container>
</template>

<script>
import ChangeRequest from "./ChangeRequest";
import ChangelogDe from "./ChangelogDe";
import ChangelogEn from "./ChangelogEn";

export default {
  components: {
    ChangeRequest,
    ChangelogDe,
    ChangelogEn,
  },
};
</script>
