<template>
  <div class="mb-5">
    <h3>{{ $t("project.change request.label") }}</h3>
    <v-form
      id="changerequestform"
      ref="formchangerequest"
      @submit.prevent="sendChangeRequest"
    >
      <v-textarea
        v-model="message"
        style="font-family: monospace"
        filled
        :rules="rules.message"
      ></v-textarea>
      <div class="text-end pb-5">
        <v-btn type="submit" color="primary">{{ $t("Send") }}</v-btn>
      </div>
    </v-form>
    <v-alert tile v-if="changed" type="success" class="mb-0">{{
      $t("project.change request.success")
    }}</v-alert>
    <v-alert tile v-if="errorMessage" type="error" class="mb-0">{{
      $t(errorMessage)
    }}</v-alert>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: "",
      form: {
        email: "",
        message: "",
      },
      changed: false,
      errorMessage: null,
      serverErrors: null,
      rules: {
        message: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              name: this.$t("project.change request.field"),
            }),
        ],
      },
    };
  },
  created() {
    if (this.loggedInUser) {
      this.form.email = this.loggedInUser.email;
    }
  },
  methods: {
    resetForm() {
      this.form.message = "";
      this.changed = false;
      this.serverErrors = {};
      this.errorMessage = null;
    },
    sendChangeRequest() {
      if (!this.$refs.formchangerequest.validate()) {
        return;
      }
      this.form.message =
        "<b>subject: <b>" +
        this.$t("project.change request.field") +
        "<br><b>message: </b>" +
        this.message;
      this.post("/entry", this.form)
        .then(() => {
          this.changed = true;
          window.setTimeout(() => {
            this.resetForm();
          }, 2500);
        })
        .catch((error) => {
          this.errorMessage = error.message || error.error;
          this.serverErrors = error.errors;
        });
    },
  },
};
</script>
